@font-face {
    font-family: curved;
    src: url(../font/greatmadecurved3.1-extraboldcondensed-webfont.woff);
}
h1, h2, h3, h4, h5, h6{
    font-family: 'curved', sans-sarif !important;
}

.font-curved {
    font-family: 'curved', sans-sarif !important;
}